import React, { useState, useEffect, useRef } from 'react';
import './AnnounceManager.css';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';

function AnnounceManager() {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [announces, setAnnounces] = useState([]);
    const [selectedAnnounce, setSelectedAnnounce] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newAnnounce, setNewAnnounce] = useState({
        name: '',
        title: '',
        description: '',
        file: ''
    });
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetch('https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/AnnounceManager.php')
            .then(response => response.json())
            .then(data => setAnnounces(data));

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 700);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => setShowAlert(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    const handleDelete = () => {
        if (selectedAnnounce && selectedAnnounce.use === 0) {
            fetch(`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/AnnounceManager.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ id: selectedAnnounce.id }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erreur lors de la suppression de l\'annonce.');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.message === 'Annonce supprimée avec succès.') {
                        setAnnounces(announces.filter(announce => announce.id !== selectedAnnounce.id));
                        setSelectedAnnounce(null);
                        setMessage('Annonce supprimée avec succès.');
                        setMessageType('success');
                        setShowAlert(true);
                    }
                })
                .catch(() => {
                    setMessage('Erreur lors de la suppression de l\'annonce.');
                    setMessageType('error');
                    setShowAlert(true);
                });
        }
    };

    const handleSave = () => {
        const formData = new FormData();
        formData.append('name', newAnnounce.name);
        formData.append('title', newAnnounce.title);
        formData.append('description', newAnnounce.description);
        if (fileInputRef.current && fileInputRef.current.files[0]) {
            formData.append('file', fileInputRef.current.files[0]);
        }

        fetch(`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/AnnounceManager.php`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === 'Annonce créée avec succès.') {
                    window.location.reload(); 
                } else {
                    setMessage(data.message);
                    setMessageType('error');
                    setShowAlert(true);
                }
            })
            .catch(() => {
                setMessage('Erreur lors de l\'enregistrement de l\'annonce.');
                setMessageType('error');
                setShowAlert(true);
            });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewAnnounce({ ...newAnnounce, file: URL.createObjectURL(file) });
        }
    };

    const handlePublish = () => {
        if (selectedAnnounce && selectedAnnounce.use === 0) {
            fetch(`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/AnnounceManager.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ id: selectedAnnounce.id }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erreur lors de la publication de l\'annonce.');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.message === 'Annonce publiée avec succès.') {
                        const updatedAnnounces = announces.map(announce => 
                            announce.id === selectedAnnounce.id ? { ...announce, use: 1 } : { ...announce, use: 0 }
                        );
                        setAnnounces(updatedAnnounces);
                        setSelectedAnnounce({ ...selectedAnnounce, use: 1 });
                        setMessage('Annonce publiée avec succès.');
                        setMessageType('success');
                        setShowAlert(true);
                    }
                })
                .catch(() => {
                    setMessage('Erreur lors de la publication de l\'annonce.');
                    setMessageType('error');
                    setShowAlert(true);
                });
        }
    };

    const handleDeactivateAll = () => {
        const areAllDeactivated = announces.every(announce => announce.use === 0);
        if (areAllDeactivated) {
            setMessage('Toutes les annonces sont déjà désactivées.');
            setMessageType('error');
            setShowAlert(true);
            return;
        }

        fetch(`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/AnnounceManager.php`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ disableAll: true }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === 'Toutes les annonces ont été désactivées avec succès.') {
                    const updatedAnnounces = announces.map(announce => ({ ...announce, use: 0 }));
                    setAnnounces(updatedAnnounces);
                    setSelectedAnnounce(selectedAnnounce ? { ...selectedAnnounce, use: 0 } : null);
                    setMessage('Toutes les annonces ont été désactivées avec succès.');
                    setMessageType('success');
                    setShowAlert(true);
                } else {
                    setMessage(data.message);
                    setMessageType('error');
                    setShowAlert(true);
                }
            })
            .catch(() => {
                setMessage('Erreur lors de la désactivation des annonces.');
                setMessageType('error');
                setShowAlert(true);
            });
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    if (isSmallScreen) {
        return (
            <div className="message-small-screen">
                Veuillez agrandir votre fenêtre pour utiliser le panel administrateur.
            </div>
        );
    }

    return (
        <div className='background-announce-manager'>
            {showAlert && (
                <Alert
                    message={message}
                    type={messageType}
                    onClose={() => setShowAlert(false)}
                />
            )}
            <div className='container-announce-manager'>
                <button className='btn-disconnect' onClick={handleLogout}>Déconnexion</button>
                <button className='btn-desactive-all' onClick={handleDeactivateAll}>Désactiver</button>
                <div className='list-announce'>
                    {announces.map(announce => (
                        <div className='box' key={announce.id} onClick={() => {
                            setSelectedAnnounce(announce);
                            setIsAddingNew(false);
                        }}>
                            <h1>{announce.name}</h1>
                        </div>
                    ))}
                </div>
                <div className='creator-announce'>
                    <div className='header'>
                        <h1>Panel administrateur</h1>
                        <div className='container-btn-add'>
                            <button onClick={() => {
                                setIsAddingNew(true);
                                setSelectedAnnounce(null);
                            }}>+</button>
                        </div>
                    </div>
                    <div className='container'>
                        {isAddingNew ? (
                            <>
                                <div className='container-text'>
                                    <div className='input-box'>
                                        <label htmlFor="newAdvertisementName">Nom de la publicité</label>
                                        <input
                                            type="text"
                                            id="newAdvertisementName"
                                            name="newAdvertisementName"
                                            value={newAnnounce.name}
                                            onChange={(e) => setNewAnnounce({ ...newAnnounce, name: e.target.value })}
                                        />
                                    </div>
                                    <div className='input-box'>
                                        <label htmlFor="newTitle">Titre</label>
                                        <input
                                            type="text"
                                            id="newTitle"
                                            name="newTitle"
                                            value={newAnnounce.title}
                                            onChange={(e) => setNewAnnounce({ ...newAnnounce, title: e.target.value })}
                                        />
                                    </div>
                                    <div className='input-box'>
                                        <label htmlFor="newDescription">Description</label>
                                        <textarea
                                            id="newDescription"
                                            name="newDescription"
                                            value={newAnnounce.description}
                                            onChange={(e) => setNewAnnounce({ ...newAnnounce, description: e.target.value })}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='container-image' onClick={() => fileInputRef.current.click()}>
                                    {newAnnounce.file ? (
                                        <img src={newAnnounce.file} alt="Aperçu de l'image" />
                                    ) : (
                                        <p>Importer une image</p>
                                    )}
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </>
                        ) : selectedAnnounce ? (
                            <>
                                <div className='container-text'>
                                    <div className='input-box'>
                                        <label htmlFor="advertisementName">Nom de la publicité</label>
                                        <input type="text" id="advertisementName" name="advertisementName" value={selectedAnnounce.name} readOnly />
                                    </div>
                                    <div className='input-box'>
                                        <label htmlFor="title">Titre</label>
                                        <input type="text" id="title" name="title" value={selectedAnnounce.title} readOnly />
                                    </div>
                                    <div className='input-box'>
                                        <label htmlFor="description">Description</label>
                                        <textarea id="description" name="description" value={selectedAnnounce.description} readOnly></textarea>
                                    </div>
                                </div>
                                <div className='container-image'>
                                    {selectedAnnounce.file ? (
                                        <img src={`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/AnnounceImage/${selectedAnnounce.file}`} alt="Aperçu de l'image" /> 
                                    ) : (
                                        <p>Importer une image</p>
                                    )}
                                </div>
                            </>
                        ) : (
                            <h3>Créer ou Renouveler une annonce</h3>
                        )}
                    </div>
                    <div className='container-action'>
                        {isAddingNew ? (
                            <button className="save-button" onClick={handleSave}>Enregistrer</button>
                        ) : selectedAnnounce && (
                            <>
                                {selectedAnnounce.use === 0 && (
                                    <button className="delete-button" onClick={handleDelete}>Supprimer</button>
                                )}
                                <button
                                    className="publish-button"
                                    onClick={handlePublish}
                                    disabled={selectedAnnounce.use === 1}
                                    style={{
                                        backgroundColor: selectedAnnounce.use === 1 ? '#5376d6' : '',
                                        cursor: selectedAnnounce.use === 1 ? 'not-allowed' : 'pointer',
                                    }}
                                    onMouseEnter={(e) => {
                                        if (selectedAnnounce.use === 1) {
                                            e.target.style.backgroundColor = '#4467c7';
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (selectedAnnounce.use === 1) {
                                            e.target.style.backgroundColor = '#5376d6';
                                        }
                                    }}
                                >
                                    {selectedAnnounce.use === 1 ? 'Actif' : 'Publier'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnnounceManager;
