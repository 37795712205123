import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Funiculaire/Header/Header';
import Footer from './Funiculaire/Footer/Footer';
import Home from './Funiculaire/Home/Home';
import Connection from './Funiculaire/Connection/Connection';
import Team from './Funiculaire/Team/Team';
import Legal from './Funiculaire/Legal/Legal';
import AnnounceManager from './Funiculaire/AnnounceManager/AnnounceManager';
import PrivateRoute from './Funiculaire/Services/PrivateRoute';
import { AuthProvider } from './Funiculaire/Services/AuthContext';
import NoMatch from './Funiculaire/Services/NoMatch';
import Announce from './Funiculaire/Announce/Announce';

const Layout = ({ children }) => {
    const location = useLocation();
    const currentPath = location.pathname.toLowerCase();

    const showHeaderFooter = currentPath !== '/connexion' && currentPath !== '/panel' && currentPath !== '/mentions';

    const showAnnounce = currentPath !== '/connexion' && currentPath !== '/panel' && currentPath !== '/mentions';

    return (
        <div>
            {showHeaderFooter && <Header />}
            {children}
            {showHeaderFooter && <Footer />}
            {showAnnounce && <Announce />}
        </div>
    );
};


const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/equipe" element={<Team />} />
                        <Route path="/connexion" element={<Connection />} />
                        <Route path="/mentions" element={<Legal />} />
                        <Route path="/panel" element={<PrivateRoute element={<AnnounceManager />} />} />
                        <Route path="*" element={<NoMatch />} /> 
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
};

export default App;
