import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
    const navigate = useNavigate();

    const handleMentionsClick = () => {
        navigate('/mentions');
    };

    return (
        <footer>
            <div className='container-top'>
                <div className='box'>
                    <h1>Nos coordonnées</h1>
                    <span>Garage du Funiculaire Sàrl</span>
                    <span>Route de la Crottaz 4,<br />1802 Corseaux</span>
                </div>
                <div className='box'>
                    <h1>Nos horaires</h1>
                    <div className='container-hours'>
                        <span>Lun-Jeu :</span>
                        <div className='hours'>
                            <span>7h30 - 12h00</span>
                            <span>13h30 - 17h40</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Vendredi :</span>
                        <div className='hours'>
                            <span>7h30 - 12h00</span>
                            <span>13h30 - 17h00</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Samedi :</span>
                        <div className='hours'>
                            <span>Sur RDV</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Dimanche :</span>
                        <div className='hours'>
                            <span>Fermé</span>
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <h1>Contactez-nous</h1>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faPhone} />
                        <a href="tel:+41219212526" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>+41 21 921 25 26</span>
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faEnvelope} />
                        <a href="mailto:info@garagedufuniculaire.ch" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>info@garagedufuniculaire.ch</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='container-center'>
                <h6 onClick={handleMentionsClick} style={{ cursor: 'pointer' }}>Mentions légales</h6>
                <h5>© 2024 tout droit réservé à Garage du Funiculaire Sàrl.</h5>
            </div>
            <div className='container-bottom'>
                Conçu par <a href="https://www.logixcube.ch/" style={{ textDecoration: 'none', color: '#ffffff' }} target="_blank" rel="noopener noreferrer">Logixcube.ch</a>
            </div>
        </footer>
    );
}

export default Footer;