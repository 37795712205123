import React, { useState, useEffect } from 'react';
import './Announce.css';

const Announce = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [announcement, setAnnouncement] = useState(null);
    const [isClosing, setIsClosing] = useState(false);

    const closePopup = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsVisible(false);
            localStorage.setItem('announceClosed', 'true');
        }, 1000); 
    };

    useEffect(() => {
        localStorage.removeItem('announceClosed');

        const isAnnounceClosed = sessionStorage.getItem('announceClosed');
        if (!isAnnounceClosed) {
            fetch(`https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Backend/Announce.php`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        setAnnouncement(data[0]);
                        setIsVisible(true);
                    }
                })
                .catch(error => console.error('Erreur :', error));
        }
    }, []);

    if (!isVisible || !announcement) {
        return null;
    }

    const imagePath = `https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/AnnounceImage/${announcement.file}`;

    return (
        <div className={`announce-overlay ${isClosing ? 'hidden' : ''}`}>
            <div className={`announce-popup ${isClosing ? 'hidden' : ''}`}>
                <div className='container-text'>
                    <button className="announce-close" onClick={closePopup}>&times;</button>
                    <h2>{announcement.title}</h2>
                    <p>{announcement.description}</p>
                </div>
                <div className='container-img'>
                    <img src={imagePath} alt={announcement.title} />
                </div>
            </div>
        </div>
    );
};

export default Announce;
