import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Typewriter from 'typewriter-effect';
import './Home.css';
import Background from '../Images/Background.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faCarSide, faWrench, faCarBurst, faBicycle } from '@fortawesome/free-solid-svg-icons';
import Contact from '../Contact/Contact';

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1440);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1440);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        dots: false, 
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 3000,
        arrows: false, 
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3, 
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    const boxes = [
        {
            className: 'box-1',
            icon: faHandshake,
            title: 'Notre équipe',
            description: 'Rencontrez notre équipe et découvrez les personnes qui partagent notre engagement envers nos clients.',
            link: '/equipe'
        },
        {
            className: 'box-2',
            icon: faCarSide,
            title: 'Vente',
            description: "Explorez notre gamme de véhicules neufs et d'occasion à des prix compétitifs.",
            link: 'https://www.autoscout24.ch/fr/s/seller-65926'
        },
        {
            className: 'box-3',
            icon: faWrench,
            title: 'Services',
            description: 'Profitez de nos services de maintenance et de réparation pour garder votre véhicule en parfait état.',
            link: '#'
        },
        {
            className: 'box-4',
            icon: faCarBurst,
            title: 'Dépannage',
            description: 'Interventions rapides et efficaces pour tous vos besoins de dépannage automobile.',
            link: 'https://riviera-depannage.ch/'
        },
        {
            className: 'box-5',
            icon: faBicycle,
            title: 'E-bike',
            description: 'Découvrez notre sélection de vélos électriques pour une mobilité verte et innovante.',
            link: 'https://www.ebike-riviera.ch/'
        }
    ];

    return (
        <div className='background-home'>
            <div className='container-home-1'>
                <div className='header-image'>
                    <img src={Background} alt="Background" />
                    <div className='backdrop'>
                        <div className='container-text'>
                            <h2>
                                <Typewriter
                                    options={{
                                        autoStart: true,
                                        loop: true,
                                    }}
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString("Bienvenue")
                                            .pauseFor(2500)
                                            .deleteAll()
                                            .typeString("Willkommen")
                                            .pauseFor(2500)
                                            .deleteAll()
                                            .typeString("Benvenuto")
                                            .pauseFor(2500)
                                            .deleteAll()
                                            .typeString("Welcome")
                                            .pauseFor(2500)
                                            .deleteAll()
                                            .start();
                                    }}
                                />
                            </h2>
                            <h1>Garage du Funiculaire Sàrl</h1>
                        </div>
                    </div>
                </div>
                <div className='container-box'>
                    {isMobile ? (
                        <Slider {...settings}>
                            {boxes.map((box, index) => (
                                <a 
                                    key={index} 
                                    href={box.link || '#'} 
                                    target={box.link.startsWith('/') ? '_self' : '_blank'} 
                                    rel={box.link.startsWith('/') ? '' : 'noopener noreferrer'}
                                    className={`box ${box.className}`}
                                >
                                    <div className='container-icon'>
                                        <div className='border-icon'>
                                            <FontAwesomeIcon className='icon' icon={box.icon} />
                                        </div>
                                    </div>
                                    <h3>{box.title}</h3>
                                    <p>{box.description}</p>
                                </a>
                            ))}
                        </Slider>
                    ) : (
                        <div className='static-boxes'>
                            {boxes.map((box, index) => (
                                <a 
                                    key={index} 
                                    href={box.link || '#'} 
                                    target={box.link.startsWith('/') ? '_self' : '_blank'} 
                                    rel={box.link.startsWith('/') ? '' : 'noopener noreferrer'}
                                    className={`box ${box.className}`}
                                >
                                    <div className='container-icon'>
                                        <div className='border-icon'>
                                            <FontAwesomeIcon className='icon' icon={box.icon} />
                                        </div>
                                    </div>
                                    <h3>{box.title}</h3>
                                    <p>{box.description}</p>
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <Contact />
        </div>
    );
};

export default Home;
